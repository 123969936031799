import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";


class VideogrammetrySection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Simultaneous Localization and Mapping (SLAM)</h2>
              <h4>Constructing a coarse map of the environment and determining the robot's location in it using only 360° video as an input</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Nokia robot demo",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe src="https://player.vimeo.com/video/345008696?h=98d709bdd6" width="1024" height="600" 
                      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Custom algorithm",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe src="https://player.vimeo.com/video/338325299?h=a1b8adc63d" width="1024" height="600" 
                      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                    )
                },                
                {
                  tabName: "Kivisydän (360 video input)",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe src="https://player.vimeo.com/video/473767484?h=2db5bc58c4" width="1024" height="600" 
                      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Kivisydän (3D map and route)",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe src="https://player.vimeo.com/video/473767243?h=832d17bec7" width="1024" height="600" 
                      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Zion valley demo",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe src="https://player.vimeo.com/video/312812283?h=c08e1f73a6" width="1024" height="600" 
                      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                    )
                },              
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Videogrammetry and SLAM (Simultaneous Localization and Mapping) are two related 
                          technologies used in computer vision and robotics. Videogrammetry is very close to
                          photogrammetry: it is a technology that can be used to create 3D models from 2D images.
                          Instead of capturing large number of still photos, images are extracted from a video
                          file or a live camera stream. SLAM is a technology that can be used to construct a map
                          of the environment and determine the robot's location in it. 360° video is particularly
                          useful here, as it allows the robot to see the environment from all directions at the 
                          same time and makes fairly precise localization possible without additional sensors.
                        </p>
                        <p>
                          We have experimented with videogrammetry and SLAM for several years, and have created 
                          workflows and custom algorithms. Here are a few public examples of our work.
                          Kivisydän is a local underground parking complex, where we captured low-quality 360° 
                          video by driving around with a car and computed a 3D map of the environment and driven 
                          route. Zion valley demo is similar but in much larger scale. We also have a video
                          showing how individual 360° video frames ("image bubbles") are connected to each other
                          by our custom videogrammetry algorithm to determine the route user has walked inside
                          our office tower. Nokia robot demo shows how we have applied SLAM to a robot that can
                          navigate in an office building without any other means than a 360° camera (5G VIIMA 
                          research project).
                        </p>
                        <p>
                          The benefit of using video as a source for photogrammetry (i.e. videogrammetry) is that 
                          a large number of images can be captured very easily in a short time. Using 360° video
                          makes it even better, as all directions can be captured at the same time. The camera can 
                          be mounted on a stick that user carries in his hand or for example attached to a helmet. 
                          The camera stick can be easily pushed into tight spaces and cavities, to capture complete 
                          3D models of machines, buildings, and other objects.
                        </p>
                        <p>
                          SLAM is often performed using a laser scanner, which is a very accurate sensor. However,
                          laser scanners are expensive and require more space. They also contain moving parts
                          that may be sensitive to vibrations. 360° video is a much cheaper and more compact
                          alternative, and as a side effect provides a video tour or still 360° images. However, 
                          it is not as accurate as laser scanning and requires more processing power either locally 
                          or in the cloud. The quality of the 3D map and the robot's location in it depends on the
                          quality of the 360° video: the more accurate the video, the better the results. All SLAM 
                          methods benefit from tags, beacons or control points that provide ground truth of the 
                          location every now and then. Without them, the localization errors slowly accumulate. 
                          For example, returning to the exact same spot might not make the route ends meet on the map.
                        </p>
                        <p>
                          Being an expert in 360° video creation, streaming and playback, Finwe has also explored
                          other uses for it. Videogrammetry and indoor positioning via SLAM are just two examples.
                        </p>
                        <p>
                          <b>
                            To learn more about videogrammetry and SLAM solutions, contact us.
                          </b>
                        </p>
                        <Button
                          round
                          color="success"
                          size="md"
                          href="contact-page/"
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          Contact us
                        </Button>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(VideogrammetrySection);
